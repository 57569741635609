import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4><a parentName="h4" {...{
        "href": "/"
      }}>{`home`}</a>{` / `}<a parentName="h4" {...{
        "href": "/resume"
      }}><em parentName="a">{`resume`}</em></a>{` / `}<a parentName="h4" {...{
        "href": "/projects"
      }}>{`projects`}</a></h4>
    <hr></hr>
    <h1>{`>_ resume`}</h1>
    <p><em parentName="p">{`Information Technology leader with more than 10 years of technology experience in high growth environments. Over 4 years of experience managing teams between 5-20 people and partnering with business leaders to achieve company goals. Interpersonal trust is critical in building strong teams. One cannot improve what one does not measure.`}</em></p>
    <h3>{`Experience`}</h3>
    <pre><code parentName="pre" {...{}}>{`Head of IT Operations | April 2022 – Present
Cortica Inc.

●   Led Software Development, DevOps, and IT Engineering / Operations / Support.
●   Developed and operated an annual budget and continuously increased operational leverage.
●   Built a technology portfolio that consists of over 15 mission critical products and growing.
●   Drove strategic initiatives and staffing plans that scaled company 100 end-users to more than 1400.
●   Developed in-house patient appointment reminder system
●   Automated complex business processes that resulted in thousands of labor hours saved per year and increasing.

`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`Director, IT Operations (promotion) | June 2019 – April 2022

●   Managed 7-person team that provided IT Service Delivery, Engineering, and Support to over 1400 users.
●   Designed in-house secure patient shared doc “portal” experience
●   Pioneered device configuration and security compliance program for thousands of registered devices.
●   Built a multi-regional service desk that fields more than 20,000 tickets per year.
●   Established 12 new offices and orchestrated technology side of acquisitions.

`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`Information Security Engineer August 2017 – June 2019
J. Craig Venter Institute

- Led infrastructure decisions relating to network design, infosec, data availability, and recovery procedures
- Architected deployment of single sign-on and multifactor authentication to over 200 users
- Performed SOC2 internal and external risk assessments and certification process
- Created, communicated, and maintained security P&Ps compliant with NIST 800- 53
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`Systems Engineer | March 2016 – August 2017
Ingenu
- Supervised helpdesk support and IT engineering team in a 200-user environment
- Managed 2 data center environments totaling of 28 servers, 8 network devices, and 4 firewalls
- Managed and designed core networks, high performance compute, and storage for 24x7 uptime
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`Systems Administrator | October 2015 – March 2016
J. Craig Venter Institute
- Established operational excellence of systems including patch management and risk management
- Spearheaded the design, implementation, and maintenance of a monitoring and logging infrastructure
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`Network Systems Administrator | October 2012 – October 2015
Maxlinear
- Managed a large deployment of Palo Alto firewalls throughout global site locations
- Monitored more than 100 network devices to evaluate and improve network performance, utilization, and latency
`}</code></pre>
    <h3>{`Skills`}</h3>
    <pre><code parentName="pre" {...{}}>{`Management 
Strategic Planning • Negotiation • IT Management • IT Strategy • Budgeting • Vendor Management • Compliance • Project Management • Digital Transformation • Information Security • Software Development • Contact Center • Cloud Solutions

Technical 
Amazon Web Services (AWS) • Azure • CI/CD • Network Design • Automation • UCaaS • SD-WAN • SSO • Programming • Microsoft Power Automate • Salesforce • Git • TypeScript/JavaScript • Node • Serverless • IT Infrastructure • CRM • Virtualization • SharePoint • Hardware • Windows • MacOS • Data Management • Website Development
`}</code></pre>
    <h3>{`Education`}</h3>
    <pre><code parentName="pre" {...{}}>{`CISSP : Certified Information Systems Security Professional (ISC)²
BS : Computer Science [May 2018] California State University Monterey Bay
AS : Mathematics [May 2013] San Diego Miramar College
`}</code></pre>
    <h5><a parentName="h5" {...{
        "href": "/"
      }}>{`back to home`}</a>{` / `}<a parentName="h5" {...{
        "href": "#top"
      }}>{`top of page`}</a></h5>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      